<ion-app>
  <ion-header class="ion-no-border" [translucent]="true">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button color="primary"></ion-menu-button>
      </ion-buttons>

      <ion-grid>
        <ion-row>
          <ion-col>
            <div class="logo" *ngIf="(authService.isAuthenticated$ | async)" routerLink="overview">
              <img class="partner-logo" [src]="(partnerLogo$ | async)" (error)="partnerLogoError($event)" />
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>

  <ion-split-pane contentId="main-content" class="content-margin">
    <ion-menu contentId="main-content" type="overlay" class="ion-menu-side">
      <ion-list *ngIf="(isCustomerLoading$ | async)" style="margin-top: 50px; margin-left: 10px;" lines="none">
        <ion-item style="margin-bottom:30px">
          <ion-avatar style="margin-right: 22px; margin-left: 5px" slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>

          <ion-label>
            <div>
              <ion-skeleton-text animated style="border-radius: 10px; margin-bottom: 10px"></ion-skeleton-text>
            </div>

            <div>
              <ion-skeleton-text animated style="width: 70%; border-radius: 10px"></ion-skeleton-text>
            </div>
          </ion-label>
        </ion-item>



        <div *ngFor="let page of appPages">
          <ion-item class="menu-tab" *ngIf="(authService.hasPermissionObservable(page.permission) | async)">
            <ion-avatar style="width: 25px; height: 25px; margin-left: 18px; margin-right: 30px;" slot="start">
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-avatar>

            <ion-label>
              <ion-skeleton-text animated style="border-radius: 10px"></ion-skeleton-text>
            </ion-label>
          </ion-item>
        </div>
      </ion-list>

      <ion-content>
        <ion-list *ngIf="!(isCustomerLoading$ | async) && (authService.userProfile$) | async as profile" id="inbox-list" class="side-nav">
          <ion-grid style="margin-bottom: 30px">
            <ion-row class="sidebar-user-profile">
              <ion-col size="1" style="min-width: 55px" class="ion-align-self-center">
                <ion-avatar>
                  <img style="border-radius: 1.1rem" [src]="profile.picture" />
                </ion-avatar>
              </ion-col>

              <ion-col>
                <div>
                  <ion-list-header style=" font-weight: 700; margin-top: 4px; font-size: 19px;">
                    {{ profile.given_name }}
                    {{ profile.family_name }}
                  </ion-list-header>

                  <ion-note class="note" style="margin-bottom: 0">{{(customer$ | async)?.name}}</ion-note>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-menu-toggle auto-hide="false" *ngFor="let page of appPages; let i = index">
            <ion-item class="menu-tab" *ngIf="(authService.hasPermissionObservable(page.permission) | async)" routerDirection="root" (click)="navigateTo(page.url, i)"  
              routerLinkActive="selected" lines="none" detail="false"
              [class.selected]="selectedIndex == i">
              <ion-icon *ngIf="page.icon" slot="start" [name]="page.icon"></ion-icon>
              <img *ngIf="page.customIcon" style="margin-right: unset; margin-inline-end: 30px; width: 25px;"
                [src]="page.customIcon" />
              <ion-label>{{ page.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>

      <div style="display: flex; justify-content: center; margin: 20px 0">
        <ion-button class="ion-flex ion-justify-content-center ion-button" color="primary" size="small"
          (click)="authService.logout()">Log Out</ion-button>
      </div>
    </ion-menu>

    <ion-router-outlet style="background-color: white" id="main-content"></ion-router-outlet>


  </ion-split-pane>
</ion-app>
