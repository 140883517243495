import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/modules/authentication/services/guard/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from 'src/modules/authentication/services/interceptor/interceptor.service';
import { AuthService } from 'src/modules/authentication/services/auth/auth.service';
import { PreloadingService } from './preloading.service';

let defaultRoute = '/overview';

const routes: Routes = [

  {
    path: 'customer',
    loadChildren: () => import('../modules/customer/customer.module').then((m) => m.CustomerModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'microsoft',
    loadChildren: () => import('../modules/microsoft/microsoft.module').then(m => m.MicrosoftModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'overview',
    loadChildren: () => import('../modules/overview/overview.module').then((m) => m.OverviewModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'iaas',
    loadChildren: () => import('../modules/iaas/iaas.module').then(m => m.IaasModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'dropbox',
    loadChildren: () => import('../modules/dropbox/dropbox.module').then(m => m.DropboxModule),
    canActivate: [AuthGuard],
  },

  {
    path: '',
    redirectTo: defaultRoute,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadingService,
  })],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
})
export class AppRoutingModule {}
